<template>
  <div class="widget-model mb-3" :class="{'loading' : loading}">
    <div v-if="loading" class="loading-layer"></div>
    <component :is="componentSelected" v-bind="componentParams" @widgetLoaded="loadingEnd()"></component>
  </div>
</template>
<script>

import { defineAsyncComponent } from "vue";
import componentsDefinitions from "@/components/componentsDefinitions.js";

export default {
  name: 'WidgetModel',
  props: [
    'component',
  ],
  data() {
    return {
      componentSlug: this.component.slug,
      componentParams: this.component.params,
      componentSelected:  '',
      loading: true,
    };
  },
  created() {
    const compDef = componentsDefinitions[this.componentSlug].path;
    this.componentSelected = defineAsyncComponent(() => import(`@/components${compDef}`));
  },
  methods: {
    loadingEnd(){
      this.loading = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
