const componentsDefinitions = {
	'swap': {
		'path': '/Widgets/Crypto/Swap/Swap.vue'
	},
	'packs': {
		'path': '/Widgets/Crypto/Packs/Packs.vue'
	},
	'mainCryptosList': {
		'path': '/Widgets/Crypto/Dashboard/templates/MainCryptosList.vue'
	},
	'myCryptos': {
		'path': '/Widgets/Crypto/Dashboard/templates/MyCryptos.vue'
	},
	'login': {
		'path': '/Widgets/Login/Login.vue'
	},
	'signUp': {
		'path': '/Widgets/Login/SignUp.vue'
	},
	'forgotPassword': {
		'path': '/Widgets/Login/ForgotPassword.vue'
	},
	'tradingViewCandles': {
		'path': '/Widgets/Crypto/Charts/TradingViewCandles.vue'
	},
	'tokenDetail': {
		'path': '/Widgets/Crypto/Tokens/TokenDetail.vue'
	},
	'rankingGainersLosers': {
		'path': '/Widgets/Crypto/Tokens/RankingGainersLosers.vue'
	},
	'hotPairs': {
		'path': '/Widgets/Crypto/Tokens/HotPairs.vue'
	},
	'trending': {
		'path': '/Widgets/Crypto/Tokens/Trending.vue'
	},
	'cardanoPairs': {
		'path': '/Widgets/Crypto/Tokens/CardanoPairs.vue'
	},
	'banner': {
		'path': '/Widgets/Banner/Banner.vue'
	},
	'htmlDisplay': {
		'path': '/Widgets/HTMLDisplay/HTMLDisplay.vue'
	},
	'highlightedInfo': {
		'path': '/Widgets/Promotional/HighlightedInfo.vue'
	},
	'highlightedSideImage': {
		'path': '/Widgets/Promotional/HighlightedSideImage.vue'
	},
	'faqs': {
		'path': '/Widgets/Faqs/Faqs.vue'
	},
	'PageUnauthorized401': {
		'path': '/Pages/PageUnauthorized401.vue'
	},
	'PageForbidden403': {
		'path': '/Pages/PageForbidden403.vue'
	},
	'PageNotFound404': {
		'path': '/Pages/PageNotFound404.vue'
	}
}

export default componentsDefinitions;